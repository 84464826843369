import { ICheckboxStyles, IDetailsColumnStyles, mergeStyles, mergeStyleSets } from '@fluentui/react';

export const styles = mergeStyleSets({
  disabledCheckbox: {
    color: '#A19F9D',
  },
  chevronIcon: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
    color: '#0078D4',
    marginRight: 10,
  },
  imageStyle: {
    height: '16px',
    width: '16px',
  },
  textStyle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'dropdown',
    maxWidth: '190px',
    cursor: 'pointer',
  },
  checkboxDefault: {
    padding: '4px',
  },
});

export const nameStyle = (isContainerOpen: boolean): string => {
  var fontWeight: number = 400;
  if (isContainerOpen) {
    fontWeight = 600;
  }

  const styles = mergeStyleSets({
    nameStyle: {
      fontSize: '14px',
      fontWeight: fontWeight,
      textOverflow: 'ellipsis',
      '@media (min-width: 320px) and (max-width: 320px) and (min-height: 250px) and (max-height: 270px)': {
        whiteSpace: 'normal',
        overflow: 'visible',
      },
    },
  });

  return styles.nameStyle;
};

export const style = mergeStyles({
  display: 'block',
  marginBottom: '10px',
});

export const defaultCheckboxStyle: ICheckboxStyles = {
  checkbox: {
    borderColor: '#323130',
  },
};

export const disabledCheckboxStyle: ICheckboxStyles = {
  checkbox: {
    borderColor: '#A19F9D',
  },
};

export const tableHeaderStyles: Partial<IDetailsColumnStyles> = {
  cellName: {
    marginTop: '10px',
  },
};

import * as React from 'react';
import { styles, tableHeaderStyles, nameStyle, disabledCheckboxStyle, defaultCheckboxStyle } from './list.styles';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  DetailsRow,
} from '@fluentui/react/lib/DetailsList';
import { FC, ReactElement, useState, useContext, useEffect } from 'react';
import * as ApiType from '../../solutionCenterApi/gen/index';
import {
  BaseButton,
  Button,
  Checkbox,
  IconButton,
  CheckboxVisibility,
  SelectionMode,
  ColumnActionsMode,
  Stack,
} from '@fluentui/react';
import { OptionalComponentsDescriptionContainer } from '../containers/optionalComponentsDescriptionContainer';
import { useIntl } from 'react-intl';
import { ResponsiveModeContext } from '../../contexts/ResponsiveModeContext';
import { ResponsiveMode } from '../../common/responsiveMode';
import { DATA_TEST_IDS, TelemetryPropertyNames } from '../../common/Constants';
import DeploymentStateContainer from '../../stateContainers/deploymentStateContainer';
import Utils from '../../utils';
import { telemetry } from '../../services/telemetryService';
import { telemetryConstants } from '../../config';
import { validateUniqueDependency } from '../../utils/applicationPackagesValidationHelper';
import RuntimeConfigStateContainer from '../../stateContainers/runtimeConfigStateContainer';
import * as WebAppApiType from '../../webAppApi/index';
import EnvironmentService from '../../services/environmentsService';
import { getSelectedComponents } from '../../utils/deployHelper';
export interface OptionalComponentsListProps {
  optionalComponents: ApiType.OptionalComponent[];
  setSelectionDetails: (selectedOCs: ApiType.OptionalComponent[]) => void;
  isAdditionalComponentsParent: boolean;
  deploymentL03s?: ApiType.L03[];
  l01RowKey?: string | undefined;
}

export const OptionalComponentsList: FC<OptionalComponentsListProps> = (
  props: OptionalComponentsListProps
): ReactElement => {
  const runtimeConfig: WebAppApiType.ClientConfiguration = RuntimeConfigStateContainer.getConfiguration();
  const enableValidationsForUpdate = runtimeConfig.featureFlag.enableValidationsForUpdate;

  const intlShape = useIntl();
  const responsiveMode = useContext(ResponsiveModeContext);
  const createButtonFlags = (items: ApiType.OptionalComponent[]): boolean[] => {
    var newItems: boolean[] = [];
    for (var i = 0; i < items.length; i++) {
      newItems[i as number] = true;
    }
    return newItems;
  };

  const [showDescriptionContainer, setShowDescriptionContainer] = useState<boolean[]>(
    createButtonFlags(props.optionalComponents)
  );

  const [selectedOCs, setSelectedOCs] = useState<ApiType.OptionalComponent[]>(
    DeploymentStateContainer.getOptionalComponents() ?? []
  );

  const handleCheckboxChange = (selectedOC: ApiType.OptionalComponent) => {
    const newSelection = Utils.getNewSelectedOptionalComponents(selectedOC, selectedOCs);
    setSelectedOCs(newSelection);
    props.setSelectionDetails(newSelection);
  };

  const handleChevronClick = (
    event: React.MouseEvent<
      HTMLAnchorElement | HTMLButtonElement | Button | HTMLDivElement | BaseButton | HTMLSpanElement,
      MouseEvent
    >,
    index: number
  ): void => {
    event.stopPropagation();
    if (index !== undefined) {
      var updatedShowDescriptionContainer: boolean[] = [...showDescriptionContainer];
      updatedShowDescriptionContainer[index as number] = !updatedShowDescriptionContainer[index as number];
      setShowDescriptionContainer(updatedShowDescriptionContainer);
    }
  };

  const selectableOCs: ApiType.OptionalComponent[] = Utils.getProductWithLicenses(props.optionalComponents);

  const onSelectAllCheckboxChange = () => {
    let newSelectableOCs: ApiType.OptionalComponent[] = selectableOCs;
    if (enableValidationsForUpdate && props.l01RowKey !== undefined) {
      const DeployableOCs: ApiType.OptionalComponent[] = props.optionalComponents.filter((x) =>
        Utils.productDependancyCheck(x, dependancyResponse)
      );
      newSelectableOCs = selectableOCs.filter((elem) => DeployableOCs.includes(elem));
    }

    const newSelection = isSelectAllChecked() ? [] : newSelectableOCs;
    setSelectedOCs(newSelection);
    props.setSelectionDetails(newSelection);
  };

  const [dependancyResponse, setDependancyResponse] = useState(undefined);
  const [packageCheck, setPackageCheck] = useState(undefined);
  const [userCheck, setUserCheck] = useState(undefined);
  const [notDataverseCheck, setNotDataverseCheck] = useState(undefined);

  useEffect(() => {
    async function fetchValidationData() {
      if (enableValidationsForUpdate && props.l01RowKey !== undefined) {
        let selectedDataverseOptionalComponentType = Utils.getDataverseOCs(props.optionalComponents);
        let selectedArmOptionalComponentType = Utils.getArmOCs(props.optionalComponents);
        let selectedPbiCiOptionalComponentType = Utils.getPbiCiOCs(props.optionalComponents);
        if (selectedDataverseOptionalComponentType.length !== 0) {
          await Promise.all([
            checkDependencies(selectedDataverseOptionalComponentType),
            validatePackages(selectedDataverseOptionalComponentType),
            runtimeConfig.userAccessModeValidation?.l01RowKeys?.includes(props.l01RowKey)
              ? userValidation()
              : setUserCheck(true),
          ])
            .then(() => {
              telemetry.logTrace(
                `Performing Pre validation check for dataverse on extending optional component`,
                telemetryConstants.severity.SEVERITY_INFO
              );
            })
            .catch((error) => {
              telemetry.logTrace(
                `Exception while Performing Pre validation check for dataverse on extending optional component`,
                telemetryConstants.severity.SEVERITY_INFO
              );
            });
        }
        if (selectedArmOptionalComponentType.length !== 0) {
          //TODO:select arm package and go with whatif check
          setNotDataverseCheck(true);
        }
        if (selectedPbiCiOptionalComponentType.length !== 0) {
          //TODO:select pbi package and go with Pbi & Ci check
          setNotDataverseCheck(true);
        }
      }
    }

    fetchValidationData();

    return () => {};
  }, []);

  const validatePackages = async (selectedOptionalComponents) => {
    let response: ApiType.InstanceValidationResponse;
    let envId: string;
    let customProps: any = {};
    try {
      const instanceId = props.deploymentL03s.find((item) => item.instanceId).instanceId;
      const envs = await EnvironmentService.getEnvironments(props.l01RowKey);
      const existingEnv: ApiType.Instance[] = envs.filter((env: ApiType.Instance) => env.id === instanceId);
      if (existingEnv.length !== 0) {
        envId = existingEnv[0].environmentId;
        customProps[TelemetryPropertyNames.environmentIdKey] = envId;
        let selectedComponents = getSelectedComponents(null, selectedOptionalComponents);
        response = await EnvironmentService.validateInstanceApplicationPackages(envId, selectedComponents);
        if (!response || response?.validationResult === false) {
          customProps[TelemetryPropertyNames.correlationIdKey] = response?.correlationId;
          telemetry.logTrace(
            `Instance ApplicationPackages validation failed: ${response?.correlationId}`,
            telemetryConstants.severity.SEVERITY_ERROR,
            customProps
          );
          setPackageCheck(false);
        } else {
          setPackageCheck(true);
          telemetry.logTrace(
            `Instance ApplicationPackages validation succeeded: ${response?.correlationId}`,
            telemetryConstants.severity.SEVERITY_INFO,
            customProps
          );
        }
      } else {
        telemetry.logTrace(`No matching environment id`, telemetryConstants.severity.SEVERITY_INFO, customProps);
        setPackageCheck(false);
      }
    } catch (error) {
      telemetry.logTrace(
        `Exception in Instance ApplicationPackages validation: ${error}`,
        telemetryConstants.severity.SEVERITY_ERROR
      );
      setPackageCheck(false);
    }
  };

  const checkDependencies = async (selectedComponents) => {
    let isDependenciesValid = await validateUniqueDependency(props.deploymentL03s, props.l01RowKey, selectedComponents);
    setDependancyResponse(isDependenciesValid);
  };

  const userValidation = async () => {
    try {
      const instanceId = props.deploymentL03s.find((item) => item.instanceId).instanceId;
      const envs = await EnvironmentService.getEnvironments(props.l01RowKey);
      const existingEnv = envs.filter((env: ApiType.Instance) => env.id === instanceId);
      if (existingEnv.length !== 0) {
        let instanceApiUrl: string | undefined = existingEnv[0].apiUrl;
        const userValidationResponse: ApiType.InstanceValidationResponse =
          await EnvironmentService.validateInstanceUser(instanceApiUrl);
        telemetry.logTrace(
          'user validation call executed with result',
          telemetryConstants.severity.SEVERITY_INFO,
          userValidationResponse
        );
        setUserCheck(userValidationResponse.validationResult);
      } else {
        telemetry.logTrace(
          'Unable to get environment Id for user validation check',
          telemetryConstants.severity.SEVERITY_ERROR
        );
        setUserCheck(false);
      }
    } catch (error) {
      telemetry.logTrace(
        `Exception in UserValidation check on extending the solution: ${error}`,
        telemetryConstants.severity.SEVERITY_ERROR
      );
      setUserCheck(false);
    }
  };

  const isSelectAllChecked = () => {
    const selectedOCsNameList = selectedOCs.map((oc) => oc.name);
    return selectableOCs.every((oc) => selectedOCsNameList.includes(oc.name));
  };

  const columns: IColumn[] = [
    {
      key: 'checkboxColumn',
      name: '',
      isIconOnly: true,
      minWidth: 30,
      maxWidth: 30,
      styles: tableHeaderStyles,
      onRenderHeader: () => {
        let preValidationApiWait = false;
        if (enableValidationsForUpdate && props.l01RowKey !== undefined) {
          preValidationApiWait = true;
          if (userCheck !== undefined && packageCheck !== undefined && dependancyResponse !== undefined) {
            preValidationApiWait = !userCheck || !packageCheck;
          } else if (notDataverseCheck !== undefined) {
            preValidationApiWait = !notDataverseCheck;
          }
        }
        return (
          <Stack>
            <Checkbox
              className={styles.checkboxDefault}
              onChange={onSelectAllCheckboxChange}
              checked={isSelectAllChecked()}
              disabled={selectableOCs.length === 0 || preValidationApiWait}
              ariaLabel={intlShape.formatMessage({
                id: 'destination.selectAllDeploymentPlaceholder',
              })}
            />
          </Stack>
        );
      },
      onRender: (item?: ApiType.OptionalComponent, index?: number) => {
        let disabled = !Utils.productHasLicense(item);
        let user = true;
        let productPackageCheckResult = true;
        let dependency = true;
        if (enableValidationsForUpdate && props.l01RowKey !== undefined) {
          if (item.type === ApiType.PackageType.ArmTemplate || item.type === ApiType.PackageType.ArmTemplateTenant) {
            // TODO: Add your code here to handle ARM template
          } else if (item.type === ApiType.PackageType.PBITemplateApp || item.type === ApiType.PackageType.CI) {
            // TODO: Add your code here to handle PBI template app or CI
          } else if (
            item.type === ApiType.PackageType.Data2 ||
            item.type === ApiType.PackageType.Data ||
            item.type === ApiType.PackageType.Solution
          ) {
            user = userCheck === undefined ? false : userCheck;
            productPackageCheckResult = packageCheck === undefined ? false : packageCheck;
            if (dependancyResponse !== undefined) {
              dependency = Utils.productDependancyCheck(item, dependancyResponse);
            } else {
              dependency = false;
            }
          }
        }
        return (
          <Checkbox
            className={styles.checkboxDefault}
            styles={
              disabled || !user || !productPackageCheckResult || !dependency
                ? disabledCheckboxStyle
                : defaultCheckboxStyle
            }
            disabled={disabled || !user || !productPackageCheckResult || !dependency}
            checked={selectedOCs.some((oc) => oc.name === item.name)}
            onChange={() => handleCheckboxChange(item)}
            ariaLabel={item.name}
          />
        );
      },
    },
    {
      key: 'iconColumn',
      name: 'FileType',
      isIconOnly: true,
      fieldName: 'icon',
      minWidth: 16,
      maxWidth: 16,
      iconName: 'Page',
      styles: tableHeaderStyles,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (optionalComponentData: ApiType.OptionalComponent) => {
        return (
          <div>
            <img alt="presentation" src={optionalComponentData.icon} className={styles.imageStyle} />
          </div>
        );
      },
    },
    {
      key: 'nameColumn',
      name: intlShape.formatMessage({
        id: 'destination.selectAllDeploymentPlaceholder',
      }),
      fieldName: 'name',
      styles: tableHeaderStyles,
      minWidth: responsiveMode === ResponsiveMode.Desktop ? 675 : 75,
      maxWidth: props.isAdditionalComponentsParent ? 675 : 775,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (optionalComponentData: ApiType.OptionalComponent, index?: number) => {
        if (index !== undefined) {
          return (
            <div className={nameStyle(showDescriptionContainer[index as number])}>{optionalComponentData.name}</div>
          );
        }
      },
    },
    {
      key: 'chevronColumn',
      name: '',
      isIconOnly: true,
      minWidth: 16,
      maxWidth: 16,
      styles: tableHeaderStyles,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item?: ApiType.OptionalComponent, index?: number) => {
        if (index !== undefined) {
          return (
            <div>
              <IconButton
                id={'chevronButton-' + item?.name}
                iconProps={{
                  iconName: showDescriptionContainer[index as number] ? 'ChevronUp' : 'ChevronDown',
                }}
                className={styles.chevronIcon}
                onClick={(event) => handleChevronClick(event, index)}
                data-selection-disabled={true}
                aria-label={intlShape.formatMessage({
                  id: showDescriptionContainer[index as number]
                    ? 'additionalComponents.expandRow'
                    : 'additionalComponents.collapsedRow',
                })}
              />
            </div>
          );
        }
      },
    },
  ];

  const renderDescriptionContainer = (item: ApiType.OptionalComponent, index: number): ReactElement | null => {
    if (showDescriptionContainer[index as number]) {
      let isPreValidationCheckPassed = true;
      if (enableValidationsForUpdate && props.l01RowKey !== undefined) {
        if (userCheck !== undefined && packageCheck !== undefined && dependancyResponse !== undefined) {
          isPreValidationCheckPassed =
            userCheck && packageCheck && Utils.productDependancyCheck(item, dependancyResponse);
        }
      }
      return (
        <OptionalComponentsDescriptionContainer
          optionalComponent={item}
          deploymentL03s={props.deploymentL03s}
          isPreValidationCheckPassed={isPreValidationCheckPassed}
        />
      );
    } else {
      return null;
    }
  };

  const onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      customStyles.root = { color: '#323130' };

      return (
        <div>
          <DetailsRow {...props} styles={customStyles} />
          {renderDescriptionContainer(props.item, props.itemIndex)}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <DetailsList
        data-testid={DATA_TEST_IDS.optionalComponentsList}
        items={props.optionalComponents}
        columns={columns}
        checkboxVisibility={CheckboxVisibility.hidden}
        setKey="set"
        onRenderRow={onRenderRow}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.justified}
        selectionPreservedOnEmptyClick={true}
        ariaLabelForSelectAllCheckbox={intlShape.formatMessage({
          id: 'additionalComponents.ariaLabelForSelectAllCheckbox',
        })}
        checkButtonAriaLabel={intlShape.formatMessage({
          id: 'additionalComponents.checkButtonAriaLabel',
        })}
      />
    </div>
  );
};

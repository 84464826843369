import { ISpinnerStyles, IStackTokens, IDropdownStyles, mergeStyleSets } from '@fluentui/react';
import { makeStyles } from '@fluentui/react-components';

export const stackTokens: IStackTokens = { childrenGap: 10 };

export const comboboxStyles = makeStyles({
  combobox: {
    display: 'grid',
    paddingLeft: '8px',
    width: '544px',
    color: '#605E5C !important',
    border: '1px solid #605E5C',
    borderRadius: '1px',
    position: 'relative', // Required for `::after` pseudo-element
    overflow: 'visible',
    fontWeight: '400',
    fontFamily:
      'SegoeUI-Regular-final,Segoe UI, Segoe, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, Tahoma, Helvetica, Arial, sans-serif',
    fontsize: '14px',
    '&::after': {
      content: '*', // Ensure this is a valid CSS string
      color: 'rgb(164, 38, 44)',
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      paddingRight: '12px',
    },
    '@media (max-width: 945px)': {
      width: '285px', // No quotes around 945px
    },
    '@media (max-width: 480px)': {
      width: '260px', // No quotes around 480px
      fontWeight: '400',
    },
  },
  options: {
    padding: '8px',
    margin: '8px',
    marginTop: 'none',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' /* Adds a soft shadow around the element */,
    color: 'rgb(50, 49, 48)',
    backgroundColor: 'white', // 100% opaque white background
  },
  label: {
    fontWeight: '600',
    fontFamily:
      'SegoeUI-Regular-final,Segoe UI, Segoe, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, Tahoma, Helvetica, Arial, sans-serif',
    fontSize: '14px',
  },
  option: {
    marginBottom: '10px',
    fontWeight: '400',
    fontFamily:
      'SegoeUI-Regular-final,Segoe UI, Segoe, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, Tahoma, Helvetica, Arial, sans-serif',
    fontSize: '14px',
  },
});

export const dropdownStyles: Partial<IDropdownStyles> = {
  root: {
    ':after': {
      content: '*',
      color: 'rgb(164, 38, 44)',
      paddingRight: '12px',
    },
  },
  dropdown: {
    width: 544,
    color: '#605E5C!important',
    marginTop: '5px',
    '@media(max-width: 945px)': {
      width: 285,
    },
    '@media(max-width: 480px)': {
      width: 260,
    },
    '&::after': {
      content: '*',
      color: 'rgb(164, 38, 44)',
      paddingRight: '12px',
    },
  },

  dropdownOptionText: {
    display: 'block',
    whiteSpace: 'none',
  },

  title: {
    color: '#605E5C',
    border: '1px solid #605E5C',
  },
  caretDown: {
    color: '#605E5C',
  },
};

export const style = mergeStyleSets({
  verticalSpacing: {
    marginTop: '20px !important',
    display: 'block',
  },
  displayNone: {
    display: 'none',
  },
  displayInline: {
    display: 'Inline-flex',
  },
  divCss: {
    minHeight: '100%',
    position: 'relative',
  },
  minWidth: {
    Width: '592px',
    '@media(max-width: 1024px)': {
      width: '592px!important',
    },
  },
  main: {
    '@media(max-width: 1024px)': {
      width: '592px!important',
    },
  },
  helpDivWidth: {
    width: '530px',
    '@media(max-width: 640px)': {
      width: '250px',
    },
    '@media(min-width: 640px)': {
      marginTop: '10px',
    },
    fontSize: '21px',
    lineHeight: '28px',
  },
  onDismissDivWidth: {
    marginRight: '10px',
    width: '10px',
  },
  dismissBtnColor: {
    color: 'black',
  },

  pageInfoWidth: {
    marginTop: '20px',
    fontSize: '16px',
    lineHeight: '20px',
  },

  iframe: {
    width: '100%',
    minHeight: 'calc(100vh - 48px)',
    borderStyle: 'none',
  },

  dialogContentPropsText: {
    fontSize: '20px',
    margin: '0',
  },
  dialogContentParagraph: {
    fontSize: '14px',
  },
  dialogContent: {
    overflowWrap: 'break-word',
  },
  dialogButton: {
    float: 'right',
    marginTop: '30px',
    marginBottom: '30px',
  },
  cookieWarningLabel: {
    paddingBottom: '15px',
    paddingTop: '4px',
    height: '25px',
    backgroundColor: '#fcf7b2',
    border: '1px solid #f3db98',
    marginTop: '30px',
    display: 'none',
  },

  footerContainer: {
    width: 'calc(100% - 1000px)',
    paddingBottom: '16px',
    bottom: 0,
    background: '#ffff',
    zIndex: 1,
    '@media(min-width: 960px)': {
      position: 'fixed',
    },
  },
});

export const spinnerLabelStyle: Partial<ISpinnerStyles> = {
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
};
